console.log('loading works.js');
// from https://stackoverflow.com/questions/12362256/addeventlistener-on-nodelist

const replyClickHandler = (e) => {
  e.preventDefault();
  comment_id = event.target.getAttribute('data-comment-reply')
  // show all reply links (including previously hidden)
  document.querySelector('.comment-box-reply-link').removeClass('d-none');
  // Hide all other comment boxes
  document.querySelector('.comment-box-hidden').addClass('d-none');
  // show this comment box
  document.querySelector('#' + comment_id).removeClass('d-none');
  // hide reply link
  document.querySelector(this).addClass('d-none');
};

function addEventListenerList(list, event, fn) {
    for (var i = 0, len = list.length; i < len; i++) {
        list[i].addEventListener(event, fn, false);
    }
}

document.addEventListener("DOMContentLoaded",function(){
  var comment_replys = document.querySelectorAll("a[data-comment-reply]")
  addEventListenerList(comment_replys, 'click', replyClickHandler);
});
